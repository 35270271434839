import axios from "axios";
import VueCookies from 'vue-cookies';
import SERVER from '@/API/url';

const settingStore = {
    namespaced: true,
    state: {
        
    },
    getters: {

    },
    mutations: {

    },
    actions: {

        // 정보가져오기
        info: ({commit}, params) => { 

            var token = VueCookies.get('reoilcms_accessToken');
            var header = "Bearer " + token;

            return new Promise((resolve, reject) => {
                axios.get(SERVER.URL + '/api/Setting/'+params.SettingKey,
                { headers: { Authorization : header} }
                ).then(res => {
                    //console.log(res);
                    resolve(res.data);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },

        // 셋팅값 수정
        edit: ({commit}, params) => { 

            var token = VueCookies.get('reoilcms_accessToken');
            var header = "Bearer " + token;

            return new Promise((resolve, reject) => {
                axios.put(SERVER.URL + '/api/Setting/'+params.SettingKey, params,
                { headers: { Authorization : header} }
                ).then(res => {
                    //console.log(res);
                    resolve(res.data);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },
        
    }
}

export default settingStore
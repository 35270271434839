<template>
    <!-- BEGIN FOOTER -->
    <div class="footer-wrapper">
        <div class="footer-section f-section-1">
            <p class="">Copyright © 2021 <a target="_blank" href="https://arrangic.com">리유</a>, All rights reserved.</p>
        </div>
        
    </div>
    <!-- END FOOTER -->
</template>

<template>
    <div :class="[$store.state.layout_style, $store.state.menu_style]">
        <component v-bind:is="layout"></component>
    </div>
</template>
<script setup>
    import { computed } from 'vue';

    import '@/assets/sass/app.scss';

    import { useMeta } from '@/composables/use-meta';
    import { useStore } from 'vuex';

    useMeta({ title: '리유 관리자' });

    const store = useStore();

    const layout = computed(() => {
        return store.getters.layout;
    });
</script>
<script>
    // layouts
    import appLayout from './layouts/app-layout.vue';
    import authLayout from './layouts/auth-layout.vue';

    import client from '@/ably';
    import store from '@/store';

    /*
    client.connection.on('connected', () => {
        //console.log('connected');
    });

    const channel = client.channels.get('reoil_cms');
    */

    export default {
        components: {
            app: appLayout,
            auth: authLayout,
        },
        data() {
            return {
                userid: "",
                clientId: ""
            };
        },
        watch: {
            $route(to, from){
                if(!to.fullPath.includes("auth")){
                    if(this.userid == ""){
                        var user = this.$store.getters['user'];
                        this.userid = user.data.userId;
                    }

                    //console.log(this.userid);

                    //this.loginAbly(this.userid);
                }
            }
        },
        mounted() {

            /*
            this.emitter.on('checkAblyLogin', this.loginAbly);
            this.emitter.on('ablyLogout', this.logoutAbly);

            var thisEl = this;

            // signal 받았을때
            channel.subscribe(function(message) {
                //console.log("signal");
                //console.log(message);
                if(message.data.includes("logout")){

                    // 모든 사이트에서 로그아웃
                    thisEl.logoutAbly();
                    if(store.state.user.loggedIn){
                        if(thisEl.userid == thisEl.clientId){
                            alert("다른 기기에서 로그인되었습니다.");
                            thisEl.logoutCms();
                        }
                    }else{
                        alert("다시 로그인해주세요.");
                        thisEl.$router.go("/auth/login");
                    }
                
                }
                
            });
            */
            
        },
        beforeUnmount(){
            //this.emitter.off('checkAblyLogin', this.loginAbly);
            //this.emitter.off('ablyLogout', this.logoutAbly);
        },
        methods: {

            /*
            // Ably 로그인
            loginAbly(data){

                this.userid = data;

                channel.presence.subscribe('enter', function(member) {
                    //console.log("Ably 로그인");
                    //console.log(member);
                });

                this.channelStatus();
            },

            // Ably 로그아웃
            logoutAbly(){
                channel.presence.subscribe('leave', function(member) {     
                    //console.log("Ably 로그아웃");
                    //console.log(member);

                    channel.presence.get(function(err, members) {
                        //console.log(members);
                    });
                });
                
                channel.presence.leaveClient(this.userid);
            },

            // 로그아웃
            logoutCms(){
                var thisEl = this;
                var user = this.$store.getters['user'];

                var params = {
                    "useruqid" : user.data.userUqId,
                };

                this.$store.dispatch('adminStore/logout', params)
                .then((res) => {
                    //console.log(res);
                    if(res.success){
                        thisEl.$store.commit('logoutUser');
                        thisEl.$router.go("/auth/login");
                    }
                    return res;
                })
                .catch(({message}) => (alert(message)));
            },

            // 채널 로그인 상태 
            channelStatus(){
                var thisEl = this;

                channel.presence.get(function(err, members) {
                    //console.log(members);

                    var memberArr = [];
                    for(var i = 0; i < members.length; i++){
                        if(members[i].clientId == thisEl.userid && members[i].action == "present"){
                            memberArr.push(members[i]);
                        }
                    }

                    //console.log(memberArr);
                    if(memberArr.length > 0){
                        for(var i=0; i < memberArr.length; i++){
                            if(memberArr[i].clientId == thisEl.userid && memberArr[i].action == "present"){
                                if(!store.state.user.loggedIn){
                                    var result = confirm("다른 곳에서 이미 로그인 되어 있습니다. 로그아웃 하시겠습니까?");
                                    if(result){
                                        channel.publish('signal', 'logout');
                                    }
                                }
                                
                                break;
                            }
                        }
                    }else{
                        
                        thisEl.clientId = thisEl.userid;
                        channel.presence.enterClient(thisEl.userid);
                        thisEl.emitter.emit('executeLogin');
                    }
                });
            },
            */

        },
        
    };
</script>

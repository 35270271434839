<template>
    <div>
        <!--  BEGIN NAVBAR  -->
        <Header></Header>
        <!--  END NAVBAR  -->

        <!--  BEGIN MAIN CONTAINER  -->
        <div
            class="main-container"
            id="container"
            :class="[!$store.state.is_show_sidebar ? 'sidebar-closed sbar-open' : '', $store.state.menu_style === 'collapsible-vertical' ? 'collapsible-vertical-mobile' : '']"
        >
            <!--  BEGIN OVERLAY  -->
            <div class="overlay" :class="{ show: !$store.state.is_show_sidebar }" @click="$store.commit('toggleSideBar', !$store.state.is_show_sidebar)"></div>
            <div class="search-overlay" :class="{ show: $store.state.is_show_search }" @click="$store.commit('toggleSearch', !$store.state.is_show_search)"></div>
            <!-- END OVERLAY -->

            <!--  BEGIN SIDEBAR  -->
            <Sidebar></Sidebar>
            <!--  END SIDEBAR  -->

            <!--  BEGIN CONTENT AREA  -->
            <div id="content" class="main-content">
                <router-view />

                <!-- BEGIN FOOTER -->
                <Footer></Footer>
                <!-- END FOOTER -->
            </div>
            <!--  END CONTENT AREA  -->

            <!-- BEGIN APP SETTING LAUNCHER -->
            <!-- <app-settings /> -->
            <!-- END APP SETTING LAUNCHER -->
        </div>
        

        <!-- 설정 Modal -->
        <div class="modal fade" id="settingModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" 
            aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-body">
                        <form>
                            <div class="form-group">
                                <label for="SettingValue" class="col-form-label">설정시간</label>
                                <div>
                                    <input v-model="SettingValue" type="text" class="form-control" />
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn" data-dismiss="modal" data-bs-dismiss="modal"><i
                                class="flaticon-cancel-12"></i> 취소</button>
                        <button type="button" class="btn btn-primary" @click="saveSettingValue();">저장</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import Header from '@/components/layout/header.vue';
    import Sidebar from '@/components/layout/sidebar.vue';
    import Footer from '@/components/layout/footer.vue';
    //import appSettings from '@/components/app-settings.vue';
</script>


<script>

import $ from 'jquery';
import VueCookies from 'vue-cookies';

export default {
    data:() => ({
        SettingKey: "reoil",
        SettingValue: "",
    }),
    mounted(){
        
        var token = VueCookies.get('reoilcms_accessToken');
        //console.log(token);
        if(token != "" && token != null && token != undefined){
            this.getSettingData();
        }
    
        //$('#settingModal').modal('show');
    },
    methods: {

        // 셋팅 정보 가져오기
        getSettingData(){

            var params = {
                "SettingKey": this.SettingKey
            };

            this.$store.dispatch('settingStore/info', params)
                .then((res) => {
                    //console.log(res);
                    if(res){
                        this.SettingValue = res.data.settingValue;
                    }
                    return res;
                })
                .catch(({message}) => (alert(message)));
        },

        // 저장
        async saveSettingValue(){

            if(this.SettingValue == ""){
                alert("셋팅값을 입력해주세요.");
                return false;
            }

            // 수정 모드
            var params = {
                "SettingKey": this.SettingKey,
                "SettingValue": this.SettingValue
            };

            this.$store.dispatch('settingStore/edit', params)
            .then((res) => {
                //console.log(res);
                if(res.success){
                    this.alertSuccess();

                    // 모달창 닫기
                    $('#settingModal').hide();
                    $('.modal-backdrop').removeClass('modal-backdrop')

                }else{
                    this.alertWarning(res.message);
                }
            })
            .catch(({message}) => (alert(message)));

        },

        alertSuccess(){
            new window.Swal({
                icon: 'success',
                title: '수정이 완료되었습니다!',
                padding: '2em'
            });
        },

        alertWarning(message){
            new window.Swal({
                icon: 'warning',
                title: message,
                padding: '2em'
            });
        },
    },
}

</script>
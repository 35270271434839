<template>
    <div>
        <!--  BEGIN NAVBAR  -->
        <div class="header-container fixed-top">
            <header class="header navbar navbar-expand-sm">
                <ul class="navbar-item theme-brand flex-row text-center">
                    <li class="nav-item theme-logo">
                        <router-link to="/">
                            <img src="@/assets/images/logo.png" class="navbar-logo" alt="logo" />
                        </router-link>
                    </li>
                </ul>
                <div class="d-none horizontal-menu">
                    <a href="javascript:void(0);" class="sidebarCollapse" data-placement="bottom" @click="$store.commit('toggleSideBar', !$store.state.is_show_sidebar)">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            class="feather feather-menu"
                        >
                            <line x1="3" y1="12" x2="21" y2="12"></line>
                            <line x1="3" y1="6" x2="21" y2="6"></line>
                            <line x1="3" y1="18" x2="21" y2="18"></line>
                        </svg>
                    </a>
                </div>

                <div class="navbar-item flex-row ms-md-auto">
                    <div class="dropdown nav-item btn-group align-content-center settingBtn">
                        <button type="button" class="btn btn-primary settBtn" data-bs-toggle="modal" data-bs-target="#settingModal">설정</button>
                    </div>

                    <div class="dropdown nav-item user-profile-dropdown btn-group">
                        <a href="javascript:;" id="ddluser" data-bs-toggle="dropdown" aria-expanded="false" class="btn dropdown-toggle btn-icon-only user nav-link">
                            <span>{{ name }}</span>
                        </a>
                        <ul class="dropdown-menu dropdown-menu-right" aria-labelledby="ddluser">

                            <li role="presentation">
                                <a href="javascript:void(0);" @click="logout();" class="dropdown-item">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        class="feather feather-log-out"
                                    >
                                        <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
                                        <polyline points="16 17 21 12 16 7"></polyline>
                                        <line x1="21" y1="12" x2="9" y2="12"></line>
                                    </svg>
                                    로그아웃
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </header>
        </div>
        <!--  END NAVBAR  -->
        <!--  BEGIN NAVBAR  -->
        <div class="sub-header-container">
            <header class="header navbar navbar-expand-sm">
                <a href="javascript:void(0);" class="sidebarCollapse" data-placement="bottom" @click="$store.commit('toggleSideBar', !$store.state.is_show_sidebar)">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-menu"
                    >
                        <line x1="3" y1="12" x2="21" y2="12"></line>
                        <line x1="3" y1="6" x2="21" y2="6"></line>
                        <line x1="3" y1="18" x2="21" y2="18"></line>
                    </svg>
                </a>

                <!-- Portal vue/Teleport for Breadcrumb -->
                <div id="breadcrumb" class="vue-portal-target"></div>
            </header>
        </div>
    </div>
</template>

<style>
.navbar{
    background-color: #fff !important;
}
.navbar .theme-brand li.theme-logo img{
    width: auto !important;
}
.btn-group .btn.settBtn{
    height: 30px;
}

.btn-group.settingBtn {
    align-items: center;
}
</style>

<script setup>
    import { onMounted, ref } from 'vue';
    import { useI18n } from 'vue-i18n';
    import { useStore } from 'vuex';
    const store = useStore();

    const selectedLang = ref(null);
    const countryList = ref(store.state.countryList);

    const i18n = useI18n();

    onMounted(() => {
        selectedLang.value = window.$appSetting.toggleLanguage();
        toggleMode();
    });

    const toggleMode = (mode) => {
        window.$appSetting.toggleMode(mode);
    };

    const changeLanguage = (item) => {
        selectedLang.value = item;
        window.$appSetting.toggleLanguage(item);

        i18n.locale.value = item.code;
    };
</script>


<script>
export default {
    data() {
        return {
            userUqId: "",
            name: "",
        };
    },
    components: {
    },
    created(){
    },
    mounted(){

    },
    watch: {
        $route(to, from){
            // 우측 상단 사용자 이름 가져오기
            if(!to.fullPath.includes("auth")){
                if(this.name == ""){
                    var user = this.$store.getters['user'];
                    this.userUqId = user.data.userUqId;
                    this.getUserInfo();
                }
            }
        }
    },
    beforeUnmount(){
    },
    methods: {
        // 회원정보 가져오기
        getUserInfo(){
            var params = {
                "UqId" : this.userUqId,
            };

            this.$store.dispatch('userStore/info', params)
            .then((res) => {
                //console.log(res);
                if(res.success){
                    this.name = res.data.name;
                }
                return res;
            })
            .catch(({message}) => (alert(message)));
        },

        // 로그아웃
        logout(){
            var thisEl = this;

            var params = {
                "useruqid" : this.userUqId,
            };

            this.$store.dispatch('adminStore/logout', params)
            .then((res) => {
                //console.log(res);
                if(res.success){
                    thisEl.$store.commit('logoutUser');
                    thisEl.$router.go("/auth/login");
                }
                return res;
            })
            .catch(({message}) => (alert(message)));
        },
    }
}
</script>

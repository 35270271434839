import axios from "axios";
import VueCookies from 'vue-cookies';
import SERVER from '@/API/url';

const priceStore = {
    namespaced: true,
    state: {
        
    },
    getters: {

    },
    mutations: {

    },
    actions: {

        // 리스트 가져오기
        getList: ({commit}, params) => { 

            var token = VueCookies.get('reoilcms_accessToken');
            var header = "Bearer " + token;

            return new Promise((resolve, reject) => {
                axios.post(SERVER.URL + '/api/Price/GetList', params,
                { headers: { Authorization : header} }
                ).then(res => {
                    //console.log(res);
                    resolve(res.data);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },

        // 삭제
        delete: ({commit}, params) => { 

            var token = VueCookies.get('reoilcms_accessToken');
            var header = "Bearer " + token;

            return new Promise((resolve, reject) => {
                axios.delete(SERVER.URL + '/api/Price/'+params,
                { headers: { Authorization : header} }
                ).then(res => {
                    //console.log(res);
                    resolve(res.data);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },

        // 등록
        register: ({commit}, params) => { 

            var token = VueCookies.get('reoilcms_accessToken');
            var header = "Bearer " + token;

            return new Promise((resolve, reject) => {
                axios.post(SERVER.URL + '/api/Price', params,
                { headers: { Authorization : header} }
                ).then(res => {
                    //console.log(res);
                    resolve(res.data);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },

        // 수정
        edit: ({commit}, params) => { 

            var token = VueCookies.get('reoilcms_accessToken');
            var header = "Bearer " + token;

            return new Promise((resolve, reject) => {
                axios.put(SERVER.URL + '/api/Price/'+params.EffectiveDate, params,
                { headers: { Authorization : header} }
                ).then(res => {
                    //console.log(res);
                    resolve(res.data);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },

        // 정보가져오기
        info: ({commit}, params) => { 

            var token = VueCookies.get('reoilcms_accessToken');
            var header = "Bearer " + token;

            return new Promise((resolve, reject) => {
                axios.get(SERVER.URL + '/api/Price/'+params.EffectiveDate,
                { headers: { Authorization : header} }
                ).then(res => {
                    //console.log(res);
                    resolve(res.data);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },

        // 엑셀다운로드
        exportExcel: ({commit}, params) => { 

            window.location = SERVER.URL + '/api/Price/ExportExcel?startdate='+params.StartDate+'&enddate='+params.EndDate;

        },
        
    }
}

export default priceStore
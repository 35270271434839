import axios from "axios";
import VueCookies from 'vue-cookies';
import SERVER from '@/API/url';

const usersStore = {
    namespaced: true,
    state: {
        
    },
    getters: {

    },
    mutations: {

    },
    actions: {

        // 로그인
        login: ({commit}, params) => { 

            return new Promise((resolve, reject) => {
                axios.post(SERVER.URL + '/api/Users/adminlogin/', params
                ).then(res => {
                    //console.log(res);
                    resolve(res.data);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },

        // token 재발급
        refresh_token: ({commit}, params) => { 

            return new Promise((resolve, reject) => {
                axios.post(SERVER.URL + '/api/Users/refresh_token/', params
                ).then(res => {
                    //console.log(res);
                    resolve(res.data);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },

        // 로그아웃
        logout: ({commit}, params) => { 

            var token = VueCookies.get('reoilcms_accessToken');
            var header = "Bearer " + token;

            return new Promise((resolve, reject) => {
                axios.post(SERVER.URL + '/api/Users/logout?useruqid='+ params.userUqId, params,
                { headers: { Authorization : header} }
                ).then(res => {
                    //console.log(res);
                    resolve(res.data);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },
    }
}

export default usersStore